<script setup lang="ts">
import {requireInject} from '@/common/utils/InjectUtil'
import {PRODUCT_SELECTION_INJECTION} from '@/common/services/Products/productSelectionInjection'
import {VsgFlexWrapper, VsgIconChevronRight, VsgListItem} from '@jack-henry/jha-vue-components'
import {$inj} from '@/common/decorators/depinject'
import {BltSearch} from '@/common/services/Search/SearchFactory'
import {computed, ref, watchEffect} from 'vue'
import LOAN_GROUPS from '@/common/components/productSelection/LoanGroupsEnum'
import ProductTypeIcon from '@/common/components/product-type-icon/productTypeIcon.vue'
import BltHelperLink from '@/common/components/bltHelperLink/bltHelperLink.vue'
import type {IAccountTypeGroup} from '@/common/services/Products/accountGroupService'

const injection = requireInject(PRODUCT_SELECTION_INJECTION)
const bltSearch = $inj(BltSearch)

const productGroups = computed(() =>
  injection.values.productGroups.value.filter((product) => product.parentGroup === 'LOAN')
)

const productGroupsSearched = computed(() => {
  return bltSearch.for(productGroups.value, ['typeDescription'], injection.values.searchTerm?.value ?? '')
})

const hasAnyResults = computed(() => {
  return productGroupsSearched.value.length > 0
})

const onSelectProductGroup = (productGroup: IAccountTypeGroup) => {
  injection.methods.setSelectedSubgroup(productGroup)
  injection.methods.goToPane('PRODUCT_SUBGROUP')
}
</script>
<template>
  <div
    v-if="hasAnyResults"
    class="jh-font-heading-medium-1">
    Loan
  </div>

  <vsg-list-item
    v-for="(productGroup, index) in productGroups"
    @click="onSelectProductGroup(productGroup)"
    :key="productGroup.typeCode"
    :class="{'last-product-group': index === productGroups.length - 1}">
    <vsg-flex-wrapper>
      <product-type-icon
        fill-color="var(--jha-color-primary)"
        :group-type-code="productGroup.typeCode" />

      <div class="jh-font-body-regular-1 jha-text-dark mr-4">{{ productGroup.typeDescription }}</div>

      <blt-helper-link
        v-if="
          injection.values.productSubgroupGroupDetails.value.get(productGroup.typeCode)?.productFeatureDisclosureCode
        "
        :disclosure-code="injection.values.productSubgroupGroupDetails.value.get(productGroup.typeCode)?.productFeatureDisclosureCode!" />
    </vsg-flex-wrapper>

    <vsg-icon-chevron-right slot="right" />
  </vsg-list-item>
</template>

<style scoped>
@import '../../../branchmanager/assets/typography.css';
@import '../../../branchmanager/assets/typography-colors.css';

jha-list-item {
  cursor: pointer;
  margin-left: 0;
  --jha-list-item-horizontal-spacing: 0;
  --jha-list-item-vertical-spacing: var(--jh-space-300);
}

.last-product-group {
  margin-bottom: var(--jh-space-400);
  border-bottom: 0;
}

jha-icon-chevron-right {
  fill: var(--account-card-secondary-background-color);
}
</style>
