import type {DeepReadonly, InjectionKey, Ref} from 'vue'
import type {Product} from '@/common/models/IProductSelection'
import type {ProductsSubgroupTaxplan} from "@/common/services/Products/productsSubgroupTaxplanService";
import type IAccount from "@/common/models/IAccount";
import type {IAccountTypeGroup} from '@/common/services/Products/accountGroupService'
import type {FundingIntention} from "@/common/services/Products/fundingIntentionService";
import type IApplicant from "@/common/models/IApplicant";
import type RestResponse from "@/common/data/RestResponse";
import type {ProductSubgroupDetail} from "@/common/services/Products/productSubgroupDetailsService";

interface ProductSelectionInjection {
  values: {
    productList: DeepReadonly<Ref<Array<Product>>>
    searchTerm?: DeepReadonly<Ref<string>>
    accountList: DeepReadonly<Ref<Array<IAccount>>>
    productGroups: DeepReadonly<Ref<Array<IAccountTypeGroup>>>
    productSubgroups: DeepReadonly<Ref<Map<string, Array<IAccountTypeGroup>>>>
    productSubgroupTaxPlans: DeepReadonly<Ref<Map<string, ProductsSubgroupTaxplan>>>
    productSubgroupGroupDetails: DeepReadonly<Ref<Map<string, ProductSubgroupDetail>>>
    selectedSubgroup: DeepReadonly<Ref<IAccountTypeGroup | undefined>>
    fundingIntentions: Ref<Array<FundingIntention>>
    applicantList: DeepReadonly<Ref<Array<IApplicant>>>
  }
  methods: {
    setSelectedSubgroup: (subgroup: IAccountTypeGroup | undefined) => void
    goToPane: (pane: ProductSelectionPanes) => void
    addProduct: (product: Product) => Promise<IAccount>
    deleteAccount: (account: DeepReadonly<IAccount>) => Promise<void>
    updateAccount?: (account: DeepReadonly<IAccount>) => Promise<IAccount>
    saveIntentions: () => Promise<Array<RestResponse<FundingIntention>>>
  }
  channel: 'open' | 'branch'
}

export type ProductSelectionPanes = 'PRODUCT_GROUPS' | 'PRODUCT_SUBGROUP' | 'SELECTED_ACCOUNT_LIST' | 'FUNDING_INTENTION'

export const PRODUCT_SELECTION_INJECTION: InjectionKey<ProductSelectionInjection> = Symbol('product-selection-state')
